export default [
  {
    id: 'organizations',
    name: 'superadmin.sections.organizations',
    icon: 'folder_open',
    route: { name: 'superadmin-organizations' },
    conditions: 'all',
  },
  {
    id: 'activity',
    name: 'superadmin.sections.activity',
    icon: 'schedule',
    route: { name: 'superadmin-activity' },
    conditions: 'all',
  },
  {
    id: 'poeple',
    name: 'superadmin.sections.people',
    icon: 'person_outline',
    route: { name: 'superadmin-people' },
    conditions: 'all',
  },
]
