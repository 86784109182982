<template>
  <main-layout
    :entity-name="$t('superadmin.name')"
    entity-logo="/logo.svg"
    :sidebar-items="sidebarItems"
  >
    <router-view />
  </main-layout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import sidebarItems from './sidebarItems'

export default {
  name: 'Superadmin',
  components: {
    MainLayout,
  },
  data() {
    return {
      isDataLoaded: false,
      sidebarItems,
    }
  },
}
</script>
